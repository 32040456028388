function getMarginClasses(margins) {
  if (!margins) {
    return "";
  }

  return margins
    ?.map(({ screenSize, side, value }) => {
      return `${side}-${screenSize ? `${screenSize}-` : ""}${value / 8}`;
    })
    .join(" ");
}

export { getMarginClasses };
