// Default imports
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageHead from "../components/Page/PageHead";
import ButtonText from "../components/ButtonText";
import { getMarginClasses } from "../helpers/customMarginsHelpers";

const TextContent = ({
  title,
  content,
  template: {
    textRichContent: { pageHead, flexibleContent },
  },
}) => {
  return (
    <Container className="text-content">
      <PageHead {...pageHead} />
      <Row>
        {content ? (
          <Col
            lg="16"
            className="text-content__content cmb-7"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          ""
        )}

        {flexibleContent?.map(
          ({ fieldGroupName, margin, ...blockData }, index) => {
            switch (true) {
              case /FlexibleContent_Text$/.test(fieldGroupName):
                return (
                  <Col
                    key={`text-content-${fieldGroupName}-${index}`}
                    lg="16"
                    className={`text-content__content ${getMarginClasses(
                      margin
                    )}`}
                    dangerouslySetInnerHTML={{ __html: blockData.text }}
                  />
                );

              case /FlexibleContent_Button$/.test(fieldGroupName):
                return (
                  <Col
                    key={`text-content-${fieldGroupName}-${index}`}
                    lg="16"
                    className={`text-content__button ${getMarginClasses(
                      margin
                    )}`}
                  >
                    <ButtonText {...blockData.link} variant="red">
                      {blockData.text}
                    </ButtonText>
                  </Col>
                );

              default:
                return <></>;
            }
          }
        )}
      </Row>
    </Container>
  );
};

export default TextContent;
